@charset "UTF-8";
.bg-nacre {
  background-color: #F4F1EA;
}

.bg-miel {
  background: #DEA95B;
}

html {
  font-size: clamp(12px, 0.75vw + 9px, 15px);
}

body {
  font-family: "Manrope", sans-serif;
  font-size: clamp(12px, 0.23vw + 0.71rem, 1rem);
  color: #88330D;
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: "Lora", sans-serif;
  line-height: 1.2;
}

h1, h2 {
  font-weight: bold !important;
  font-style: italic !important;
}

h1 {
  font-size: clamp(17px, 0.23vw + 0.71rem, 1.3rem);
}

h2 {
  font-size: clamp(17px, 0.23vw + 0.71rem, 1.3rem);
  margin-bottom: 0;
}

h3 {
  font-size: 1.1rem;
}

button:focus {
  outline: none;
}

.cont {
  margin-bottom: 80px;
  max-width: 1920px;
  margin: auto;
}

.grand-btn {
  width: 350px;
}

#map {
  height: 400px;
}

.card:hover {
  transform: scale(1.02);
  transition: all linear 200ms;
  z-index: 1;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  flex-basis: 250px;
  align-items: flex-start;
  justify-content: center;
}

.product {
  box-sizing: border-box;
  float: left;
  width: 33%;
  padding: 10px 20px;
  text-indent: 0;
}

.product-show {
  width: 66%;
}

.product-header {
  text-align: center;
  padding: 5px 0;
  height: 3em;
  font-size: 1.2em;
  font-weight: 500;
  text-indent: 0;
  background-color: rgba(192, 192, 192, 0.6784313725);
}

.product-show-img {
  width: 50%;
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
}

.product-img {
  width: 100%;
}

.product-footer {
  float: left;
  width: 100%;
}

.bondecommande {
  width: 100%;
  padding-bottom: 20px;
}

.bondecommande-titre {
  color: rgb(49, 49, 49);
  margin-left: 2rem;
}

.text {
  display: none;
}

.title:target + .text {
  display: block;
}

/* Suppression des flèches sur les input de type number.
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.list-group-item.active {
  background-color: grey;
  border-color: grey;
}

.dropdown-item {
  color: #88330D;
}
.dropdown-item:active {
  background-color: #ffffff;
  color: #88330D;
}

label.required:after {
  content: " *";
}

.mb-5 {
  margin-bottom: 3rem;
}

menu {
  max-width: 1546px;
  margin: 0 auto;
  padding: 20px 5px 30px 5px;
}

.footer {
  padding: 2rem 2rem 2rem 2rem;
  /* haut | droit | bas | gauche */
  color: #88330D;
}
.footer-container {
  margin-bottom: 80px;
  max-width: 1546px;
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 1015px) {
  .footer-container {
    flex-direction: column;
  }
}
.footer a {
  color: #88330D;
  text-decoration: underline;
  line-height: 38px;
}
.footer h2 {
  font-size: 1.2rem;
  margin-bottom: 22px;
}
.footer-2columns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
@media (max-width: 1015px) {
  .footer-2columns {
    width: 100%;
    justify-content: flex-start;
    gap: 80px;
  }
}
.footer-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 1015px) {
  .footer-columns {
    flex-direction: column;
    margin-top: 34px;
  }
}
.footer-column {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1015px) {
  .footer-column {
    margin-top: 34px;
  }
}
.footer-CGV {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  font-weight: bold;
  margin-top: 40px;
  align-items: center;
}
@media (max-width: 1015px) {
  .footer-CGV {
    flex-direction: column;
    margin-top: 40px;
  }
}
.footer-logo img {
  width: clamp(188px, 0.5vw + 188px, 235px);
}

.button {
  margin: 5px 5px;
  border-radius: 1.5em;
  text-transform: uppercase;
  padding: 5px 20px;
  cursor: pointer;
  border: solid;
  transition: background-color 0.5s ease, color 0.5s ease;
  height: 45px;
  align-items: center;
  user-select: none;
}

.button:not(:disabled):not(.disabled) {
  cursor: pointer;
  text-decoration: none;
}

.button-plein-clair {
  background-color: #DEA95B;
  border-color: #DEA95B;
  color: #ffffff;
}

.button-plein-clair:hover {
  background-color: #ffffff;
  border-color: #DEA95B;
  color: #DEA95B;
}

.button-vide-clair {
  background-color: #ffffff;
  border-color: #DEA95B;
  color: #DEA95B;
}

.button-vide-clair:hover {
  background-color: #DEA95B;
  border-color: #DEA95B;
  color: #ffffff;
}

.button-plein-fonce {
  background-color: #88330D;
  border-color: #88330D;
  color: #ffffff;
}

.button-plein-fonce:hover {
  background-color: #ffffff;
  border-color: #88330D;
  color: #88330D;
}

.button-vide-fonce {
  background-color: #ffffff;
  border-color: #88330D;
  color: #88330D;
}

.button-vide-fonce:hover {
  background-color: #88330D;
  border-color: #88330D;
  color: #ffffff;
}

.flat-input {
  border: none;
  background-color: inherit;
  outline: none;
  width: 40px;
  text-align: center;
  font-weight: bold;
  color: inherit;
  user-select: none;
  margin-bottom: 10px;
}

.quantity-button {
  min-width: 25px;
  text-align: center;
  font-weight: bold;
  font-size: clamp(25px, 0.68vw + 0.8rem, 2rem);
  line-height: clamp(25px, 0.68vw + 0.8rem, 2rem);
}

.label-quantity {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.button-menu {
  display: block;
  padding-top: 8px;
  margin-top: 25px;
}

body {
  margin: 0;
  padding: 0;
}

.nav-mobile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s transform linear;
}
.nav-mobile:hover {
  transform: rotate(90deg);
}

.header {
  height: 50px;
  width: calc(100% - 10px);
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.header img {
  max-height: 40px;
}
.header svg {
  width: 20px;
  height: 20px;
}

.mobile-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
}
.mobile-overlay.active {
  display: block;
}

.nav-mobile {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #111827;
  padding: 50px 20px;
  transform: translateX(100%);
  transition: 0.4s transform ease-in-out;
}
.nav-mobile.active {
  transform: initial;
}
.nav-mobile-profil {
  text-align: center;
  border-bottom: 1px solid #485563;
  padding-bottom: 16px;
}
.nav-mobile-profil img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.nav-mobile-profil .name {
  color: #d1d5db;
  font-size: 18px;
  display: block;
  margin: 6px 8px;
}
.nav-mobile-profil .actions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
}
.nav-mobile-profil .actions a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}
.nav-mobile-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 24px;
  margin-bottom: 24px;
}
.nav-mobile-menu li {
  margin-bottom: 12px;
}
.nav-mobile-menu li:last-of-type {
  margin-bottom: initial;
}
.nav-mobile-menu li a {
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

.menu {
  justify-content: space-around;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #ffffff;
}
.menu .desktop {
  display: none;
  flex-direction: row;
}
@media (min-width: 1015px) {
  .menu .desktop {
    flex-direction: row;
    display: flex;
  }
}
.menu .mobile {
  display: flex;
}
@media (min-width: 1015px) {
  .menu .mobile {
    flex-direction: column;
    display: none;
  }
}
.menu li a {
  text-decoration: none;
}
.menu-link {
  text-transform: uppercase;
  margin-top: 30px;
  position: relative;
  padding: 7.5px;
  display: block;
  font-weight: 700;
  height: 35px;
  text-decoration: none;
}
.menu-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #DEA95B;
  transform: scaleX(0);
  transition: 0.3s transform ease-in-out;
}
.menu-link:hover {
  color: #88330D;
}
.menu-link:hover:after {
  transform: scaleX(1);
}
.menu-link.selected {
  color: #88330D;
  font-weight: normal;
}
.menu-link.selected:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #88330D;
  transform: scaleX(1);
  transition: 0.3s transform ease-in-out;
}
.menu-link.selected:hover {
  color: #88330D;
}
.menu-link.selected:hover:after {
  transform: scaleX(0);
}
.menu-button {
  display: flex;
  flex-direction: row;
}
.menu-logo img {
  height: clamp(67px, 5.23vw + 46px, 113px);
}
.menu-burger {
  margin-top: 30px;
  position: relative;
  padding: 7.5px;
  font-weight: 700;
}
.menu-burger svg {
  height: clamp(13px, 2.73vw + 8px, 40px);
}
.menu-mobile {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  width: 390px;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px 0px;
  transform: translateX(200%);
  transition: 0.4s transform ease-in-out;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}
.menu-mobile.active {
  transform: initial;
}
.menu-mobile li {
  list-style: none;
  display: flex;
  align-self: center;
}
.menu-mobile-logo {
  align-self: center;
}
.menu-mobile-logo img {
  height: clamp(67px, 5.23vw + 46px, 113px);
}
.menu-mobile-link {
  text-transform: uppercase;
  margin-top: 30px;
  position: relative;
  padding: 7.5px;
  display: block;
  font-weight: 700;
  width: 100%;
  align-self: center;
}
.menu-mobile-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #DEA95B;
  transform: scaleX(0);
  transition: 0.3s transform ease-in-out;
}
.menu-mobile-link:hover {
  color: #88330D;
  text-decoration: none;
}
.menu-mobile-link:hover:after {
  transform: scaleX(1);
}
.menu-mobile .button {
  min-width: 230px;
  text-align: center;
}
.menu-mobile-burger {
  position: absolute;
  display: block;
  top: 60px;
  right: 30px;
}
.menu-mobile-burger svg {
  height: clamp(13px, 2.73vw + 8px, 40px);
}
.menu-mobile .dropdown-item {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px;
  padding-bottom: 15px;
  align-self: center;
  width: 200px;
  text-align: center;
}